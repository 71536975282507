import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import ContentWrapper from "../components/ContentWrapper";

const MainWrapper = styled.div`
  padding-top: 65px;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding-top: 63px;
  }
`;

const MainInner = styled.div`
  text-align: center;
  padding: 30px 0;
  .thankyou-title {
    font-size: 1.875rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 1.5rem;
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="" description="" />
      <MainWrapper>
        <MainInner>
          <ContentWrapper>
            <h1 className="thankyou-title">お問い合わせを受け付けました</h1>
            <p>お問い合わせいただき、ありがとうございます。返信が届くまで、今しばらくお待ちください。<br /></p>
          </ContentWrapper>
        </MainInner>
      </MainWrapper>
    </Layout>
  );
};

export default NotFoundPage;